import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import i18n from "@/plugins/i18n"
Vue.use(VueRouter)
const routes = [
  {
    path: "/:lang(ru|kz)?",
    component: {
      template: "<router-view />",
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "about",
        name: "About",
        component: () => import("@/views/about/AboutParty"),
      },
      {
        path: "heating-systems/bat",
        name: "HeatingSystems",
        component: () => import("@/views/heating-systems/HeatingSystems"),
      },
      {
        path: "stocks",
        name: "stocks",
        component: () => import("@/views/stocks/PageStocks"),
      },
      {
        path: "stocks/:id",
        name: "stockItem",
        props: true,
        component: () => import("@/views/stocks/FullStocks"),
      },
      {
        path: "stocks/products/:id",
        name: "stockOnlyDiscount",
        props: true,
        component: () => import("@/views/stocks/PageStocksProducts"),
      },
      {
        path: "products/:id",
        name: "fullProduct",
        props: true,
        component: () => import("@/views/products/FullProduct"),
      },
      {
        path: "catalog",
        name: "catalog",
        component: () => import("@/views/products/PageCatalog"),
      },
      {
        path: "catalog/:id",
        name: "catalog",
        props: true,
        component: () => import("@/views/products/PageCatalog"),
      },
      {
        path: "brands",
        name: "brands",
        component: () => import("@/views/brands/PageBrands"),
      },
      {
        path: "brands/:id",
        name: "fullBrands",
        props: true,
        component: () => import("@/views/brands/FullBrands"),
      },
      {
        path: "recipes",
        name: "recipes",
        component: () => import("@/views/recipes/PageRecipes"),
      },
      {
        path: "recipes/:id",
        name: "fullRecipe",
        props: true,
        component: () => import("@/views/recipes/FullRecipe"),
      },
      {
        path: "news",
        name: "news",
        component: () => import("@/views/news/PageNews"),
      },
      {
        path: "news/:id",
        name: "newsItem",
        component: () => import("@/views/news/FullNews"),
        props: true,
      },
      {
        path: "useful-articles",
        name: "usefulArticles",
        component: () => import("@/views/useful-articles/PageUsefulArticles"),
      },
      {
        path: "useful-articles/:id",
        name: "fullUsefulArticles",
        component: () => import("@/views/useful-articles/FullUsefulArticles"),
        props: true,
      },
      {
        path: "survey",
        name: "SurveyPage",
        component: () => import("@/views/Survey"),
        meta: {
          layout: "fullscreen-layout",
        },
      },
      {
        path: "survey-pmk",
        name: "SurveyPage",
        component: () => import("@/views/Survey"),
        meta: {
          layout: "fullscreen-layout",
        },
      },
      {
        path: "dewars",
        name: "DewarsPage",
        component: () => import("@/views/Dewars"),
        meta: {
          layout: "lending-layout",
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if ((from.name === "catalog" && to.fullPath.split("&").length > 1) || to.params.isNoScroll) {
      // return {
      //   selector: to.hash,
      //   behavior: "smooth",
      // };
      return false
    }

    // if (from.name === "catalog") {
    //   return false;
    // }

    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang
  if (!language) {
    language = "ru"
    to.params.lang = language
    // router.push({ name: to.name });
    // next({ name: to.name, params: { lang: language }, query: to.query });
  }

  // set the current language for vuex-i18n. note that translation data
  // for the language might need to be loaded first
  i18n.locale = language
  next()
})

export default router
